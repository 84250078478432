<template>
  <div class="live-box" ref="livebox">
    <!-- <headers></headers> -->
    <div class="iframe-wrapper">
      <iframe :src="liveurl" allow="microphone;camera;midi;encrypted-media;display-capture *;"></iframe>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        // liveurl:sessionStorage.url,
        liveurl: '',
        str: "",

      };
    },
    computed: {
      ...mapState({
        // url(state) {
        //   // console.log(state);
        //   return state.live.url;
        // }
      })
    },
    methods: {
      async golive() {
        let resData = await this.$Api.Project.live(this.$route.query.id);
        this.liveurl = resData.data.webUrl;
      }
    },
    activated() {
      // this.identity = sessionStorage.getItem("role")
    // this.$store.commit('changestatus', true)

      this.golive();
      // console.log("激活");
      this.str = "1";
      setTimeout(() => {
        window.scrollTo(0, 90);
      }, 20);
      // setTimeout(() => {
      //   this.showGuide = true
      // }, 10000)
    },
    deactivated() {
      // console.log("失活");
      this.str = "2";
      window.scrollTo(0, 0);
    },
    mounted() {
      // this.identity = sessionStorage.getItem("role")
    // this.$store.commit('changestatus', true)

      this.golive();
      // console.log("挂载");
      setTimeout(() => {
        window.scrollTo(0, 90);
      }, 20);
      // setTimeout(() => {
      //   this.showGuide = true
      // }, 10000)
    },
    destroyed() {
      // console.log("销毁");
      window.scrollTo(0, 0);
    }
  };
</script>

<style lang="less" scoped>
  .live-box {
    max-width: 1920px;
    min-width: 990px;
  }

  .iframe-wrapper {
    width: 100%;
    height: calc(100vh - 91px);
    overflow: hidden;
  }

  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>